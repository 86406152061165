<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    :width="800"
    title="添加收费规则"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>

    <div style="overflow-y: auto; max-height: 450px">
      <a-form :label-col="{ span: 4 }">
        <a-card>
          <template #title
            ><span style="text-align: center">公共基础设置</span></template
          >

          <a-row class="row_item" type="flex" align="middle" :gutter="16">
            <a-col :span="4">收费规则名称</a-col>
            <a-col :span="7">
              <a-input
                v-model:value="parkingBillRuleVo.name"
                allowClear
              ></a-input>
            </a-col>
            <a-col :span="2" style="text-align: center"
              ><a-divider type="vertical" style="height: 40px"></a-divider
            ></a-col>
            <a-col :span="4">免费时长</a-col>
            <a-col :span="7">
              <a-input
                v-model:value="parkingBillRuleVo.parkingFreeTime"
                allowClear
              >
                <template #addonAfter> 分钟 </template>
              </a-input>
            </a-col>
          </a-row>
          <a-row class="row_item" type="flex" align="middle" :gutter="16">
            <a-col :span="4">24小时封顶值</a-col>
            <a-col :span="7">
              <a-input v-model:value="parkingBillRuleVo.dayFeeMax" allowClear>
                <template #addonAfter> 元 </template>
              </a-input>
            </a-col>
            <a-col :span="2" style="text-align: center"
              ><a-divider type="vertical" style="height: 40px"></a-divider
            ></a-col>
            <a-col :span="4">离场免费时长</a-col>
            <a-col :span="7">
              <a-input
                v-model:value="parkingBillRuleVo.leaveFreeTime"
                allowClear
              >
                <template #addonAfter> 分钟 </template>
              </a-input>
            </a-col>
          </a-row>
          <a-row class="row_item" type="flex" align="middle" :gutter="16">
            <a-col :span="4">宽限时长</a-col>
            <a-col :span="7">
              <a-input v-model:value="parkingBillRuleVo.gracePeriod" allowClear>
                <template #addonAfter> 分钟 </template>
              </a-input>
            </a-col>
          </a-row>
        </a-card>
        <a-card>
          <template #title
            ><span style="text-align: center">收费规则设置</span></template
          >
          <a-row style="height: 40px" type="flex" align="middle" :gutter="16">
            24小时统一️标准收费规则
          </a-row>

          <div>
            <a-row
              class="row_item"
              style="padding: 0 5px"
              type="flex"
              align="middle"
              :gutter="16"
            >
              <a-radio-group v-model:value="parkingBillRuleVo.dayRuleType">
                <a-radio value="0">按固定时段收费</a-radio>
                <a-radio value="1">分时段收费</a-radio>
              </a-radio-group>
            </a-row>

            <a-row
              class="row_item"
              v-if="parkingBillRuleVo.dayRuleType == 0"
              type="flex"
              align="middle"
              :gutter="16"
            >
              <a-col :span="1">每</a-col>
              <a-col :span="4">
                <a-input
                  v-model:value="parkingBillRuleVo.dayTimeSection"
                  allowClear
                >
                </a-input>
              </a-col>
              <a-col :span="3" style="text-align: center">分钟收</a-col>
              <a-col :span="4">
                <a-input
                  v-model:value="parkingBillRuleVo.dayFeeSection"
                  allowClear
                ></a-input>
              </a-col>
              <a-col :span="2" style="text-align: center">元</a-col>
            </a-row>
            <a-row
              v-if="parkingBillRuleVo.dayRuleType == 1"
              class="row_item"
              style="height: auto"
              type="flex"
              align="middle"
              :gutter="16"
            >
              <a-row
                v-for="(item, index) in parkingBillRuleVo.billRuleDayPeriods"
                align="middle"
                :key="index"
                style="padding: 5px 5px"
              >
                <a-col :span="2">停车</a-col>
                <a-col :span="4">
                  <a-input v-model:value="item.duration" allowClear> </a-input>
                </a-col>
                <a-col :span="5" style="text-align: center">分钟以内收</a-col>
                <a-col :span="5">
                  <a-input v-model:value="item.fee" allowClear></a-input>
                </a-col>
                <a-col :span="2" style="text-align: center">元</a-col>
                <a-col :span="3"
                  ><a-button v-if="index == 0" @click="changePeriod(1, index)">
                    <PlusOutlined />
                  </a-button>
                  <a-button v-else @click="changePeriod(0, index)">
                    <MinusOutlined /> </a-button
                ></a-col>
              </a-row>
            </a-row>
          </div>

          <a-row style="height: 40px" type="flex" align="middle" :gutter="16">
            <a-checkbox v-model:checked="isNightSet"
              >晚间收费规则设置</a-checkbox
            >
          </a-row>

          <div v-if="isNightSet">
            <a-row class="row_item" type="flex" align="middle" :gutter="16">
              <a-col :span="4">晚间开始时间</a-col>
              <a-col :span="7">
                <a-time-picker
                  v-model:value="parkingBillRuleVo.nightTimeStart"
                  valueFormat="HH:mm:ss"
                  format="HH:mm:ss"
                  allowClear
                />
              </a-col>
              <a-col :span="2" style="text-align: center"
                ><a-divider type="vertical" style="height: 40px"></a-divider
              ></a-col>
              <a-col :span="4">晚间结束时间</a-col>
              <a-col :span="7">
                <a-time-picker
                  v-model:value="parkingBillRuleVo.nightTimeEnd"
                  valueFormat="HH:mm:ss"
                  format="HH:mm:ss"
                  allowClear
                />
              </a-col>
            </a-row>
            <a-row class="row_item" type="flex" align="middle" :gutter="16">
              <a-col :span="4">晚间收费</a-col>

              <a-col :span="1">每</a-col>
              <a-col :span="4">
                <a-input
                  v-model:value="parkingBillRuleVo.nightTimeSection"
                  allowClear
                >
                </a-input>
              </a-col>
              <a-col :span="3" style="text-align: center">分钟收</a-col>
              <a-col :span="5">
                <a-input
                  v-model:value="parkingBillRuleVo.nightFeeSection"
                  allowClear
                >
                </a-input>
              </a-col>
              <a-col :span="2" style="text-align: center">元</a-col>
            </a-row>
            <a-row class="row_item" type="flex" align="middle" :gutter="16">
              <a-col :span="4">晚间封顶值</a-col>
              <a-col :span="7">
                <a-input
                  v-model:value="parkingBillRuleVo.nightFeeMax"
                  allowClear
                >
                  <template #addonAfter> 元 </template>
                </a-input>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useParkingBillRule from "@/hooks/parking/useParkingBillRule";
import useMessage from "@/hooks/useMessage";
import { save } from "@/api/parking/parkingLotBillRuleApi";
import { validNumber, validFloatNumber } from "../../../../utils/validateUtils";

export default {
  name: "Add",
  props: ["modalRef"],
  emits: ["close"],
  setup(pros, { emit }) {
    const disabled = ref(false);
    const isNightSet = ref(false);
    const { parkingBillRuleVo, timeZ, moneyZ } = useParkingBillRule();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const changePeriod = (type, index) => {
      if (type == 1) {
        let tmp = {
          duration: "",
          fee: "",
        };
        parkingBillRuleVo.billRuleDayPeriods.push(tmp);
      } else {
        parkingBillRuleVo.billRuleDayPeriods.splice(index, 1);
      }
    };
    const validateParams = () => {
      if (!parkingBillRuleVo.name) {
        showTipMessage("请填写收费规则名称！", "error");
        return false;
      }
      if (
        parkingBillRuleVo.parkingFreeTime &&
        !validNumber(parkingBillRuleVo.parkingFreeTime)
      ) {
        showTipMessage("请填写免费时长！", "error");
        return false;
      }
      if (
        parkingBillRuleVo.dayFeeMax &&
        !validFloatNumber(parkingBillRuleVo.dayFeeMax)
      ) {
        showTipMessage("请填写24小时封顶值！", "error");
        return false;
      }
      if (
        parkingBillRuleVo.leaveFreeTime &&
        !validNumber(parkingBillRuleVo.leaveFreeTime)
      ) {
        showTipMessage("请填写离场免费时长！", "error");
        return false;
      }
      if (
        parkingBillRuleVo.gracePeriod &&
        !validNumber(parkingBillRuleVo.gracePeriod)
      ) {
        showTipMessage("请填写宽限时长！", "error");
        return false;
      }
      if (
        (parkingBillRuleVo.dayFeeSection &&
          !validFloatNumber(parkingBillRuleVo.dayFeeSection)) ||
        (parkingBillRuleVo.dayTimeSection &&
          !validNumber(parkingBillRuleVo.dayTimeSection))
      ) {
        showTipMessage("请填写24小时收费规则！", "error");
        return false;
      }

      if (isNightSet.value) {
        if (
          (parkingBillRuleVo.nightFeeSection &&
            !validFloatNumber(parkingBillRuleVo.nightFeeSection)) ||
          (parkingBillRuleVo.nightTimeSection &&
            !validNumber(parkingBillRuleVo.nightTimeSection)) ||
          !parkingBillRuleVo.nightTimeStart ||
          (parkingBillRuleVo.nightTimeStart === "00:00:00" &&
            parkingBillRuleVo.nightTimeEnd === "00:00:00") ||
          !parkingBillRuleVo.nightTimeEnd
        ) {
          showTipMessage("请填写晚间规则！", "error");
          return false;
        }
      }
      return true;
    };
    const doSubmit = async () => {
      if (!validateParams()) return;
      let action = await showConfirm("添加新的计费规则?");
      if (action) {
        disabled.value = true;
        await showLoading();
        try {
          let obj = {
            name: parkingBillRuleVo.name,
            parkingFreeTime: timeZ(parkingBillRuleVo.parkingFreeTime),
            dayFeeMax: moneyZ(parkingBillRuleVo.dayFeeMax),
            leaveFreeTime: timeZ(parkingBillRuleVo.leaveFreeTime),
            gracePeriod: timeZ(parkingBillRuleVo.gracePeriod),
            ruleType: isNightSet.value ? 1 : 0, //收费规则类型，0：24小时统一标准收费规则  1：晚间收费规则 ，默认为0，为1时启用晚间收费规则
            dayRuleType: parkingBillRuleVo.dayRuleType,
          };

          if (parkingBillRuleVo.dayRuleType == 0) {
            //固定时段
            obj.dayTimeSection = timeZ(parkingBillRuleVo.dayTimeSection);
            obj.dayFeeSection = moneyZ(parkingBillRuleVo.dayFeeSection);
          } else {
            //分时段收费
            obj.billRuleDayPeriods = [];
            parkingBillRuleVo.billRuleDayPeriods.forEach(item => {
              var tmp = {
                duration: timeZ(item.duration),
                fee: moneyZ(item.fee),
              };
              obj.billRuleDayPeriods.push(tmp);
            });
          }

          if (isNightSet.value) {
            //晚间
            obj.nightTimeStart = parkingBillRuleVo.nightTimeStart;
            obj.nightTimeEnd = parkingBillRuleVo.nightTimeEnd;
            obj.nightTimeSection = timeZ(parkingBillRuleVo.nightTimeSection);
            obj.nightFeeSection = moneyZ(parkingBillRuleVo.nightFeeSection);
            obj.nightFeeMax = moneyZ(parkingBillRuleVo.nightFeeMax);
          }
          await save(obj);
          await closeLoading();
          await showTipMessage("添加成功");
          refresh = true;
          visible.value = false;
        } catch (e) {
          await closeLoading();
          await showErr(e);
          disabled.value = false;
        }
      }
    };
    onMounted(async () => {});
    return {
      ...toRefs(pros),
      disabled,
      isNightSet,
      visible,
      afterClose,
      parkingBillRuleVo,
      doSubmit,
      validateParams,
      timeZ,
      moneyZ,
      changePeriod,
    };
  },
};
</script>

<style scoped>
.row_item {
  border: 1px solid lightgrey;
  height: 40px;
  border-bottom: none;
}

.row_item:last-child {
  border-bottom: 1px solid lightgrey;
}
</style>
